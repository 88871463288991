
body{
    background-color: #d5f2c1;
}
ul {
    position: relative;
    margin-top: 5%;
    margin-left: 5%;
    width: 30%;
    padding: 0;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
    display: flex;
    &:hover {
      li {
        transform: scale(1.5);
        opacity: .5;
        filter: blur(5px)
      }
    }
    li {
      display: block;
      list-style: none;
      margin: 0 10px;
      font-family: sans-serif;
      font-size: 22px;
      color: #66717c;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: .3s;
      padding: 5px;
      border-bottom: solid #40741b 2px;
      &:hover {
        transform: scale(1.8);
        opacity: 1;
        filter: blur(0);
        color: #fff;
        &:before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #7CD93B;
        transform-origin: right;
        z-index: -1;
        height: 100%;
        transform: scaleX(0);
        transition: transform .3s;
      }
    }
  }


  

.boton{
    display: flex;
      list-style: none;
      margin:20px;
      font-family: sans-serif;
      font-size: 22px;
      color: #66717c;
      text-transform: uppercase;
      letter-spacing: 2px;
      transition: .3s;
      padding: 5px;
      border-bottom: solid #45801b 2px;
      &:hover {
        transform: scale(1.8);
        opacity: 1;
        filter: blur(0);
        color: #fff;
        &:before {
          transform-origin: left;
          transform: scaleX(1);
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #7CD93B;
        transform-origin: right;
        z-index: -1;
        height: 100%;
        transform: scaleX(0);
        transition: transform .3s;}

}
.cardToken {
    width: 400px;
    height: 300px;
    position: relative;
    margin: 50px auto
  }
  
  .front,
  .back {
    width: 100%;
    height: 100%;
    
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-family: sans-serif;
    color: #2f3f0e;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: -1px -1px 12px 1px rgba(0, 0, 0, .2);
    
    
  }
  
  .front {
    background-color: #009966;
    position: absolute
  }
 
  
  
  
  .cardToken:hover div.front {
    animation: show .3s ease-in forwards
  }
  
  .cardToken:hover div.back {
    animation: show .6s ease-out forwards reverse
  }
  
  @keyframes show {
    0% {
      transform: rotateY(0deg)
    }
    100% {
      transform: rotateY(90deg)
    }
  }

  .box {
    width: 400px;
    height: 500px;
    padding: 30px;
    position: relative;
    
    
    
    overflow: hidden
  }
  
  .box:hover {
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, .3)
  }
  
  .box:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    border-top: 2px solid #7CD93B;
    border-left: 2px solid #7CD93B;
    opacity: 0;
    transition: .5s
  }
  
  .box:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: 0;
    right: 0;
    border-bottom: 2px solid #7CD93B;
    border-right: 2px solid #7CD93B;
    opacity: 0;
    transition: .5s
  }
  
  .box:hover:after {
    width: 100%;
    height: 100%;
    opacity: 1
  }
  
  .box:hover:before {
    width: 100%;
    height: 100%;
    opacity: 1
  }
  
  .box p {
    margin: 0;
    margin-top: 15px;
    padding: 0px;
    color: #66717c;
    font-size: 14px;
    line-height: 1.49;
    letter-spacing: 2px;
    font-family: Arial, Helvetica, sans-serif;
    transform: scale(.8);
    transition: .5s
  }
  
  .box:hover p {
    transform: scale(1);
    transition-delay: .3s;
  }
  
  span {
    display: block;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, .2);
    position: absolute;
    top: 0;
    left: -120%;
    transform: skew(10deg);
    transition: .8s;
    color: #66717c;
    
    
    
    
  }
  
  .box:hover span {
    left: 50%;
    font-size: large;
    font-family: sans-serif;
    color: #66717c;
    margin-top: 15px;
    
    margin-bottom: 15px;
  }
  .slides {
    position: relative;
    overflow: hidden;
    height: 500px;
    width: 80%;
  }
  
  
  .slide {
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: slide-show 12s infinite
  }
  
  .slide_2 {
    animation-delay: 4s
  }
  .slide_3 {
    animation-delay: 8s
  }
  .slide .slide_image {
    width: 80%;
    height: 80%;
    object-fit: cover
  }
  
  @keyframes slide-show {
    0% {
      opacity: 0
    } 
    10% {
      opacity: 1
    }
    20%, 30% {
      opacity: 1;
      transform: scale(1.05)
    }
    50% {
      opacity: 0
    }
  }
  .titulo{
    font-family: sans-serif;
      font-size: 22px;
      color: #66717c;

  }
  .taylorPNG{
    z-index: 350;
    position: fixed;
    left: 60%;
    width: 170px;
    height: 200px;
  }